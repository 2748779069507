import React from "react"

import Layout from "layouts/Layout"
import SEO from "components/seo/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Strona nie została znaleziona</h1>
  </Layout>
)

export default NotFoundPage
